<template>
  <div id="map" class="full-screen-map">

    <!-- Page content -->
    <div class="container pb-5 full-screen-map">


      <div class="card bg-secondary shadow border-0 full-screen-map">

        <div class="card-body full-screen-map">
          <div class="text-center text-muted mb-4">
            <h1>Escaneando QR</h1>
          </div>
          <div class="fullscreen">
            <qrcode-stream @decode="codeArrived" @init="onInit"></qrcode-stream>
            <!--<vue-qr-reader v-on:code-scanned="codeArrived" v-on:error-captured="errorCaptured" :responsive="true" style="border-width: 1px; border-style: solid; border-color: black;" />-->
          </div>
          <!--  <vue-qr-reader ref="rootElement" class="full-screen-map" v-on:code-scanned="codeArrived" v-on:error-captured="errorCaptured" :responsive="true" style="border-width: 1px; border-style: solid; border-color: black;"/>
          -->
        </div>


      </div>
      <div class="row mt-3">

        <small>Recuerde que debío dar permisos para usar la cámara</small>

      </div>




    </div>
  </div>
</template>
<script>
  // Charts
  import { QrcodeStream } from 'vue-qrcode-reader'
  export default {
    components: {
      QrcodeStream
    },
    data() {
      return {
        x: '',
        scanned: '',
        errorMessage: ''
      };
    },
    mounted() {
      let nav = document.getElementsByTagName('nav');
      if (nav.length > 0) {
        this.nav = nav[0];
      }
      this.nav.classList.add('fixed-top');
      this.nav.classList.remove('navbar-transparent');
      this.$refs.rootElement.requestFullscreen()
    },
    beforeDestroy() {
      this.nav.classList.add('navbar-transparent');
      this.nav.classList.remove('bg-white');
      this.nav.classList.remove('fixed-top');
    },
    methods: {
      codeArrived(event) {
        console.log(event);
        if (event.detail === undefined)
          this.scanned = event;
        else
          this.scanned = event.detail[0];
        window.location.href = this.scanned;
        //this.$router.push(this.scanned);
      },
      async onInit(promise) {
        try {
          await promise
        } catch (error) {
          if (error.name === 'NotAllowedError') {
            this.error = "ERROR: you need to grant camera access permission"
          } else if (error.name === 'NotFoundError') {
            this.error = "ERROR: no camera on this device"
          } else if (error.name === 'NotSupportedError') {
            this.error = "ERROR: secure context required (HTTPS, localhost)"
          } else if (error.name === 'NotReadableError') {
            this.error = "ERROR: is the camera already in use?"
          } else if (error.name === 'OverconstrainedError') {
            this.error = "ERROR: installed cameras are not suitable"
          } else if (error.name === 'StreamApiNotSupportedError') {
            this.error = "ERROR: Stream API is not supported in this browser"
          } else if (error.name === 'InsecureContextError') {
            this.error = 'ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.';
          } else {
            this.error = `ERROR: Camera error (${error.name})`;
          }
        }
      },
      errorCaptured(error) {
        switch (error.name) {
          case 'NotAllowedError':
            this.errorMessage = 'Camera permission denied.'
            break;
          case 'NotFoundError':
            this.errorMessage = 'There is no connected camera.'
            break;
          case 'NotSupportedError':
            this.errorMessage = 'Seems like this page is served in non-secure context.'
            break;
          case 'NotReadableError':
            this.errorMessage = 'Couldn\'t access your camera. Is it already in use?'
            break;
          case 'OverconstrainedError':
            this.errorMessage = 'Constraints don\'t match any installed camera.'
            break;
          default:
            this.errorMessage = 'UNKNOWN ERROR: ' + error.message
        }
        console.error(this.errorMessage);
      }
    }

  };</script>
<style>
  .full-screen-map {
    height: 100%;
  }

  #map {
    height: 100vh;
  }

  .fullscreen {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background-color: black;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }
</style>
