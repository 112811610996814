<template>
  <div class="d-flex justify-content-center">

    <div class="col-md-7 col-xl-5  ">
      <div class="px-3 mb-3">
        <h1 class="mb-1">Alta de checador</h1>
        <p v-if="Paso=='Usuario'" class="text-muted">Primero debes crear tu cuenta o iniciar sesión en ella.</p>
      </div>

      <AuthGrupoComp v-if="Paso=='Usuario'" @guardado="OnGuardadoUsuario" IniciarSesion="Ya tengo una cuenta" />

      <card v-if="Paso=='Suscripcion'" class=" card-login " headerClasses="px-0 mx-0 pt-0">
        <template slot="header" class="pb-2">
          <!-- <img class="card-img" src="img/card-primary.png" alt="Card image" />-->
          <h4 class="card-titulo  ">Registro</h4>
          <h4 class="mx-3 text-muted">Ahora registra tu empresa y serás el <b>Administrador</b> más adelante podrás cambiar de opinión.</h4>
        </template>
        <template>
          <SuscripcionesNuevo v-if="Paso=='Suscripcion'" :Cancelar="false" class="mx-2" @guardado="OnGuardadoSuscripcion" />
        </template>
      </card>

      <card v-if="Paso=='Dispositivo'" class=" card-login " headerClasses="px-0 mx-0 pt-0">
        <template slot="header" class="pb-2">
          <!-- <img class="card-img" src="img/card-primary.png" alt="Card image" />-->
          <h4 class="card-titulo">Conecta</h4>
          <div class="text-center">
            <h4 class="mx-3 text-muted">Enchufalo a la energía electrica y a Internet.</h4>
            <h1>
              <i class="fas fa-network-wired mx-3"></i>
              <i class="fas fa-wifi mx-3"></i>
              <i class="fas fa-plug mx-3"></i>

            </h1>
            <div v-if="!Encontrado">
              <b-spinner type="grow" label="cargando..." class="mb-3"></b-spinner>
              <p class="text-muted">
                Buscando dispositivo...
              </p>
            </div>
            <h1>{{NoSerie}}</h1>
            <div class="mx-5">
              <MazInput v-model="Etiqueta"
                        placeholder="Nombre o etiqueta para el checador" class="mb-3"
                        :loading="cargando" required autocomplete="QRNuevoChecadorEtiqueta"
                        clearable>

                <b-iconstack slot="icon-left" scale="1.45">
                  <b-icon stacked icon="cloud-fill" variant="info" scale="0.75"></b-icon>
                  <b-icon v-if="!Encontrado" stacked icon="slash-circle" variant="danger"></b-icon>
                </b-iconstack>


              </MazInput>

              <UbicacionesSelect placeholder="Sucursal / Ubicación" :loading="cargando" seleccionarUno position="top" class="mb-3"
                                 v-model="UbicacionID" />
              <div class="d-flex flex-row my-2 px-2 text-left">
                <MazSwitch v-model="AccesoPredeterminado" class="mr-2" />
                <p :class="!AccesoPredeterminado?'text-warning':''">{{AccesoPredeterminado?"Quienes trabajen en la sucursal tendrán acceso de forma predeterminada.":"Nadie tendrá acceso de forma predeterminada. Elegiré manualmente quien tendrá acceso mas adelante"}}</p>
              </div>
            </div>


          </div>
          <div class="mx-3">
            <hr class="my-md-4" />
            <base-button v-if="!cargando" type="primary" :disabled="!Encontrado || YaRegistrado" block @click="OnGuardarDispositivo">
              Registrar
            </base-button>

            <div class="text-muted text-center">

              <p v-if="Erroneo" class="badge badge-danger mr-2 text-wrap">
                {{ Erroneo }}
              </p>
              <div v-if="cargando" class="text-center mt-7">
                <b-spinner type="grow" label="cargando..."></b-spinner>
                Cargando...
              </div>

            </div>
          </div>

        </template>

      </card>
    </div>

  </div>
</template>


<script>
  import Referencias from '@/api-base/referencias.service';
  import Usuarios from '@/api-base/usuarios.service';
  import AuthGrupoComp from '@/Vistas-base/Usuarios/Auth.grupo.vue';
  import SuscripcionesNuevo from '@/Vistas-base/Suscripciones/Nuevo.comp.vue';
  import SuscripcionesUsuarios from '@/api-base/suscripciones.usuarios.service';
  import swal from 'sweetalert2';
  import UbicacionesSelect from '@/Vistas/Ubicaciones/Select.vue';
  import Servicio from '@/api-services/dispositivos.service';
  import { MazSwitch } from 'maz-ui';
  
  export default {
    name: 'QR-NuevoChecador-Dispositivos',
    components: {
      AuthGrupoComp,
      SuscripcionesNuevo,
      UbicacionesSelect,
      MazSwitch
    },
    data() {
      return {
        // Paso: 'Usuario',Paso: 'Suscripcion',
        Paso: 'Usuario',
        Encontrado: false,
        NoSerie: '',
        cargando: false,
        Etiqueta: '',
        Erroneo: '',
        YaRegistrado: false,
        UbicacionID: null,
        AccesoPredeterminado: true,
      };
    },
    computed: {


    },
    mounted() {
      this.$socket.on('OnConexion', this.OnConexion);

      if (Usuarios.estaLogueado()) {
        this.Paso = 'Dispositivo';
      }
      var Referencia = Referencias.getReferencia();
      if (Referencia != null && Number.isInteger(Referencia.id)) {

        //this.datos.referenciaID = Referencia.id;
        if (Referencia.tipoReferencia == 'NuevoDispositivo') {
          //Verifica si ya se encuentra registrado en la suscripción actual
          Servicio.gets(null, {
            Borrados: false,
            PaginaNo: 0,
            PaginaLen: 1,
            filtro: Referencia.extra,
          }, (response) => {
            console.debug(response.data);
            if (response.data.listado.length > 0) {
              //Ya se encuentra registrado
              //redirige a home
              this.$router.replace("/");
            }
          });
          //Mostrar alta de dispositivo
          this.NoSerie = Referencia.extra;
          this.$socket.invoke('OnConecta',
            this.NoSerie)
            .then(response => {
              console.debug(response);
              /*
              //No hizo nada el siguiente código al momento
              this.$socket.on('OnConecta', (data) => {
                console.debug(data);
              });*/
            });

        }
      }
      // this.$router.replace("/");
    },

    destroyed() {
      console.log("Componente eliminado");
      this.$socket.off('OnConexion', this.OnConexion);
    },

    methods: {
      OnConexion(resultado) {

        console.debug(resultado);

        if (this.Paso == 'Dispositivo' && resultado.noSerie == this.NoSerie) {
          if (resultado.suscripcionID != null && resultado.suscripcionID != '') {
            if (!this.YaRegistrado) {
              this.YaRegistrado = true;
              swal.fire({
                title: 'Previamente Registrado',
                text: 'El reloj checador ya se encuentra activo en otra suscripción, debe borrarse para cambiarse de suscripción.',
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-danger btn-fill'
                },
                buttonsStyling: false
              });
            }
          }
          this.Encontrado = resultado.conectado;
        }


      },
      OnGuardarDispositivo() {
        var Otro = false;
        Servicio.post(this, {
          etiqueta: this.Etiqueta,
          comentarios: '',
          ubicacionID: this.UbicacionID,
          linkID: this.NoSerie,
          internoID: this.NoSerie,
          darAcceso: this.AccesoPredeterminado ? "SoloUbicacion" : "No",
        }, (response) => {
          this.YaRegistrado = true;
          swal.fire({
            title: 'Dispositivo Guardado',
            text: `Listo, se ha agregado a tu cuenta, puedes agregar más escaneando el QR o continuar.`,
            icon: 'success',
            showCancelButton: false,
            customClass: {
              confirmButton: 'btn  btn-success ',
            },
            confirmButtonText: 'Continuar',
            buttonsStyling: false
          }).then((resultado) => {
            this.$router.replace("/");
          });

        }, (MsgError, error) => {
          this.Erroneo = MsgError;
        }, () => {
          if (Otro)
            this.cargando = true;
        });
      },
      OnGuardadoUsuario(Parametros) {
        if (Parametros.Estado != 'Nuevo') {
          this.Paso = 'Dispositivo';
          return;
        }
        this.Paso = 'Suscripcion';
      },
      OnGuardadoSuscripcion(Suscripcion) {
        //Redirigir a tutorial
        console.debug(Suscripcion);

        SuscripcionesUsuarios.setSuscripcionID(Suscripcion.suscripcionID);


        var Referencia = Referencias.getReferencia();
        if (Referencia != null && Number.isInteger(Referencia.id)) {
          //this.datos.referenciaID = Referencia.id;
          if (Referencia.tipoReferencia == 'NuevoDispositivo') {
            //Mostrar alta de dispositivo
            this.Paso = 'Dispositivo';
            return;
          }
        }


        this.$router.replace("/");
      },

    }
  };
</script>
<style lang="scss" scoped>
  .card-titulo {
    position: relative;
    font-size: 5em;
    font-weight: 900;
    /* color: black;*/
    margin-left: -5px;
    z-index: 1;
  }
</style>
